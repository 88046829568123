import React, { useState } from "react";
import { Box, Tabs, Tab, Button } from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import * as xlsx from "xlsx";
import axios from "axios";
// import moment from "moment";
// import "moment/min/locales";
import { Download, Error, FileDownloadDone } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { TokenConfig } from "../functions/TokenConfig";

import "./../css/form.css";

const menu = [
  {
    label: "Excel document",
    type: "xlsx",
    fileName: "schedule",
    api: "saveFileToJson"
  },
  {
    label: "World document",
    type: "docx",
    fileName: "description",
    api: "uploadFile/description.docx"
  },
  {
    label: "Bild",
    type: "jpg",
    fileName: "background",
    api: "uploadImage"
  },
];

export default function Form() {
  const [excelFile, setExcelFile] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [parameters, setParameters] = useState(menu[0]);
  const [confirm, setConfirm] = useState(false);
  const [success, setSuccess] = useState(false);
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

  const handleMenuChange = async (event, newValue) => {
    setUploadedFile(null);
    setExcelFile(null);
    setParameters(menu.find((x) => x.type.indexOf(newValue) > -1));
  };

  const navigate = useNavigate();
  const loc = useLocation();

  // Handle file change
  const handleFileChange = (file) => {
    setLoad(true);
    setUploadedFile(file);
    let reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = (e) => {
      if (parameters.type.indexOf("xlsx") > -1) setExcelFile(e.target.result);
      setLoad(false);
    };
  };

  // Convert excel to json
  const convertExcelToJson = () => {
    const list = [];

    // Convert excel file to json before sending
    const excelBook = xlsx.read(excelFile, { type: "buffer" });
    const excelName = excelBook.SheetNames[0];
    const worksheet = excelBook.Sheets[excelName];
    const dataExcel = xlsx.utils.sheet_to_json(worksheet);

    // let columnToRead = Object.entries(dataExcel[0]).map((column, ind) => {
    //   if (column.indexOf("tbFuRenhKorlistarad.strTomningsFrekvenskod") > -1)
    //     return column;
    // });


    dataExcel.forEach((d, ind) => {
      let dateFormat = null;

      try {
        // columnToRead.forEach((column, ind) => {
        //   if (!dateFormat)
        //     dateFormat = d[column]?.toString()?.toLowerCase() || null;
        // })

        dateFormat = d["tbFuRenhKorlistarad.strTomningsFrekvenskod"]?.toString()?.toLowerCase() || null;
        if (!dateFormat)
          dateFormat = d?.__EMPTY_12?.toString()?.toLowerCase() || null;
        if (ind === 0)
          console.log(dateFormat);
        if (dateFormat && dateFormat !== "tbFuRenhKorlistarad.strTomningsFrekvenskod".toLowerCase()) {
          // const res = convertDesignationToDate(dateFormat);
          if (dateFormat) {
            const obj = {
              address: `${d.Hamtstalle || d.__EMPTY_4}, ${d.Fastighet || d.__EMPTY_5}`,
              // date: res?.schedule,
              dateFormat: dateFormat,
              // dayOff: res?.dayOff
            };
            if (!list.find((x) => x.address === obj.address && x.date === obj.date))
              list.push(obj);
          }
        }
      } catch (error) {
        console.error("Download excel error => " + error);
        return null;
      }
    });

    return list;
  }

  // Submit uploaded file
  const onFileSubmit = async () => {
    setLoad(true);
    setConfirm(false);

    let data = null;
    if (parameters.type === "xlsx" && excelFile !== null)
      data = { schedule: convertExcelToJson() };
    else {
      data = new FormData();
      data.append("file", uploadedFile);
    }

    let seconds = 3000;
    await axios.post("app/" + parameters.api, data, TokenConfig()).then((res) => {
      setLoad(false);
      const _res = res.data;
      if (_res.message.length > 0) {
        setError(true);
        setMessage(_res.message);
        seconds = 5000;
        console.error("Responser: => " + _res.message)
      } else {
        setSuccess(true);
        setUploadedFile(null);
      }

      setTimeout(() => { resetForm(); }, seconds);
    });
  };

  // Reset form
  const resetForm = () => {
    if (loc.pathname === "/settings") {
      navigate("/reset", { replace: true });
      setTimeout(() => { navigate("/settings", { replace: true }); }, 10);
    }
  };

  return (
    <div className="content-container forms">
      <h3>Ladda upp</h3>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={parameters.type}
            onChange={handleMenuChange}
            aria-label="basic tabs"
          >
            {menu.map((m, index) => (
              <Tab
                className="btn-name"
                key={index}
                label={m.label}
                value={m.type}
                disabled={load || confirm}
              />
            ))}
          </Tabs>
        </Box>
      </Box>

      <div className="form-wrapper d-flex">
        <FileUploader
          label={`Dra och släpp eller öppna din ${parameters.label} här.`}
          hoverTitle="Ladda upp filen här."
          classes={`drag-drop d-flex d-flex-mobile${(uploadedFile && !success) ? " uploaded" : ""}${success ? " done" : ""}${error ? " error" : ""}`}
          onClick={resetForm}
          handleChange={handleFileChange}
          name={parameters.label}
          fileOrFiles={uploadedFile}
          types={parameters.type.split(",")}
          disabled={load}>

          {(!error && !uploadedFile && !success) &&
            <div className="upload-content-div">
              <Download fontSize="large" color="disabled" /> Dra och släpp eller öppna din <span>{parameters.label}</span> här.{" "}
            </div>
          }

          {(!error && uploadedFile && !load && !success) &&
            <div className="upload-content-div">
              <FileDownloadDone fontSize="large" color="primary" />
              <span>{parameters.label}</span> har laddats upp!
            </div>
          }

          {(load && !success) && <img src={require("./../assets/images/loading.gif")} width="50" height="50" alt="" />}

          {success && (<div className="upload-content-div">
            <FileDownloadDone fontSize="large" color="success" />
            <span>{parameters.label}</span> har sparats på server!</div>)}

          {error && <div className="upload-content-div"><Error fontSize="large" color="error" />{message}</div>}
        </FileUploader>

        {/* Confirm actions block */}
        <div className="buttons-wrapper d-flex d-flex-mobile">
          {confirm && (
            <>
              <p className="confirm-title">Är du säker att du vill göra det?</p>
              <Button
                type="submit"
                variant="contained"
                color="error"
                onClick={onFileSubmit}
              >
                Ja
              </Button>
            </>
          )}
          <Button
            variant="contained"
            color="primary"
            disabled={!uploadedFile || load}
            onClick={() => (confirm ? resetForm() : setConfirm(true))}
          >
            {confirm ? "Nej" : "Spara"}
          </Button>
        </div>
      </div>
    </div>
  );
};


  // Convert excel date to right date
  // const convertDesignationToDate = (str) => {
  //   if (!str) return;

  //   let dayOff = false;
  //   const num = str.slice(0, 1);
  //   let dayNumber = parseInt(num);

  //   const m = moment().locale("sv-SE");
  //   const currentDay = m.day();
  //   const currentWeek = m.week();
  //   const currentTime = m.hour();
  //   const passedDay = currentDay > dayNumber || (currentDay === dayNumber && currentTime > 6);
  //   const oddWeek = currentWeek % 2 > 0;

  //   const evenWeeksSummer = [18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38];
  //   const oddWeeksSummer = [19, 21, 23, 25, 27, 29, 31, 33, 35, 37, 39];
  //   const even4thWeeks = [2, 6, 10, 14, 18, 22, 26, 30, 34, 38, 42, 46, 50];
  //   const odd4thWeeks = [3, 7, 11, 15, 19, 23, 27, 31, 35, 39, 43, 47, 51];

  //   const holidays = [
  //     "jan 1:e", "jan 6:e",
  //     "apr 15:e", "apr 16:e", "apr 17:e", "apr 18:e",
  //     "maj 1:e", "maj 26:e", "maj 1:e",
  //     "jun 5:e", "jun 6:e", "jun 24:e", "jun 25:e",
  //     "nov 5:e",
  //     "dec 24:e", "dec 25:e", "dec 26:e", "dec 31:e"
  //   ];

  //   let date = null;

  //   switch (str.toLowerCase()) {
  //     case `${num}-10-`: // Of every week
  //       date = m.week(passedDay ? currentWeek + 1 : currentWeek);
  //       break;
  //     case `${num}-21-`: // Of odd week
  //       date = m.week(
  //         oddWeek && passedDay
  //           ? currentWeek + 2
  //           : !oddWeek
  //             ? currentWeek + 1
  //             : currentWeek
  //       );
  //       break;
  //     case `${num}-22-`: // Of even weeks
  //       date = m.week(
  //         !oddWeek && passedDay
  //           ? currentWeek + 2
  //           : oddWeek
  //             ? currentWeek + 1
  //             : currentWeek
  //       );
  //       break;
  //     case `${num}-21-ja`: // Of odd week summer (11 discharges week 19-39)
  //       date = getWeekNumber(oddWeeksSummer, currentWeek, dayNumber);
  //       break;
  //     case `${num}-22-ja`: // Of even week summer (11 discharges week 18-38)
  //       date = getWeekNumber(evenWeeksSummer, currentWeek, dayNumber);
  //       break;
  //     case `${num}-42-`: // Of every even 4:th week (with start week 2) 2,6,10,14,18,22,26,30,34,38,42,46,50
  //       date = getWeekNumber(even4thWeeks, currentWeek, dayNumber);
  //       break;
  //     case `${num}-43-`: // Of every odd 4:th week (with start week 3) 3,7,11,15,19,23,27,31,35,39,43,47,51
  //       date = getWeekNumber(odd4thWeeks, currentWeek, dayNumber);
  //       break;
  //     default:
  //       return;
  //   }

  //   if (!date?.day) return null;

  //   let scheduleDate = date?.day(dayNumber).format("dddd, MMMM D, YYYY");
  //   const scheduleDay = date?.day(dayNumber).format("MMM Do").toString();

  //   // Check is nex schedule day is holiday or not
  //   const dateWeek = date.isoWeek();
  //   if (holidays.find((x) => x === scheduleDay))
  //     dayOff = true;

  //   return {
  //     schedule: scheduleDate + ` <span style="background-color: ${dayOff ? "#eb2b2b" : (dateWeek % 2 === 0 ? "#1976D2" : "#536872")}">Vecka ${dateWeek}</span>`,
  //     dayOff: dayOff
  //   }
  // };

  // Get schedule week number
  // const getWeekNumber = (weeks, week, day) => {
  //   const m = moment().locale("sv-SE");
  //   let index = weeks.indexOf(week);

  //   while (index === -1 && week < weeks[weeks.length - 1]) {
  //     week += 1;
  //     index = weeks.indexOf(week);
  //   }

  //   if (index > -1) {
  //     if (m.isoWeek() === weeks[index]) {
  //       for (var d = 0; d < (4 - m.isoWeekday()); d++) {
  //         if (m.add(d, 'days').format("YYYY-MM-DD") === m.week(weeks[index]).day(day).format("YYYY-MM-DD")) {
  //           index += 1;
  //           if ((weeks.length - 1) < index)
  //             return m.year(m.year() + 1).week(weeks[0]);
  //         }
  //       }
  //     }

  //     return m.week(weeks[index]);
  //   } else
  //     return m.year(m.year() + 1).week(weeks[0]);
  // };