
import { useState } from "react";

// Installed
import { Routes, Route, Navigate } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";

// Pages
import Statistics from "./pages/Statistics";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Form from "./pages/Form";

// Components
import Header from "./components/Header";

// Css
import "./css/styles.css";

function App() {
  const isAuthorized = useIsAuthenticated();
  const [width, setWidth] = useState(window.innerWidth);

  window.addEventListener("orientationchange", () => {
    setTimeout(() => {
      setWidth(window.innerWidth);
    }, 10);
  });

  return (
    <div className="container d-flex">
      {/* Header */}
      <Header isAuthorized={isAuthorized} width={width} />

      <div className="wrapper d-flex">
        <Routes>
          <Route path="/" element={<Home width={width} isAuthorized={isAuthorized} />} />
          {!isAuthorized && <Route path="/login" element={<Login />} />}
          {isAuthorized && <>
            <Route path="/settings" element={<Form />} />
            <Route path="/statistics" element={<Statistics />} />
          </>}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;